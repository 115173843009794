import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core'
import { FirebaseOptions } from '@angular/fire/app'
import { SETTINGS } from '@angular/fire/compat/remote-config'
import { provideMessaging, getMessaging } from '@angular/fire/messaging'
import { providePerformance, getPerformance } from '@angular/fire/performance'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter, Router } from '@angular/router'
import { GlobalErrorHandler } from '@libs/core/services/error-handler.service'
import {
  getAppModule,
  getAuthModule,
  getFirestoreModule,
  getFunctionsModule,
  getRemoteConfigModule,
  getStorageModule,
} from '@libs/firebase'
import { RouteParamService } from '@libs/services/route-param.service'
import { getLottieProviders } from '@libs/utils/ngx-lottie'
import { TraceService, createErrorHandler } from '@sentry/angular'

import { routes } from './app.routes'

import { environment } from '~env'

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    getAppModule(environment.firebase as FirebaseOptions),
    getAuthModule(environment.useEmulators),
    getFirestoreModule(environment.useEmulators),
    getFunctionsModule(environment.useEmulators),
    getRemoteConfigModule(),
    getStorageModule(environment.useEmulators, environment.STORAGE.BUCKETS.ICON),
    getStorageModule(environment.useEmulators, environment.STORAGE.BUCKETS.FILES),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    ...getLottieProviders(),
    {
      provide: GlobalErrorHandler,
      useValue: createErrorHandler({ showDialog: false }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      deps: [TraceService],
      multi: true,
    },
    {
      provide: SETTINGS,
      useValue: {
        fetchTimeoutMillis: 60000,
        minimumFetchIntervalMillis: 60000,
      },
    },
    RouteParamService,
  ],
}
